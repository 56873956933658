<template>
  <div class="modal-login-vendor">
    <Loading :isLoading="isLoading"></Loading>
    <vue-final-modal
      :click-to-close="false"
      v-model="fncShowModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <span class="modal__title title-modal">
        <img
          src="@/assets/scgplogo.png"
          width="150"
          height="50"
          style="height: 152px; margin-bottom: -10px;"
        />
      </span>
      <div class="modal__content">
        <form @submit="submitLogin" >
          <div class="form-group">
            <input
              class="form-control"
              placeholder="Email"
              type="email"
              v-model="formData.email"
            />
            <span v-show="errorEmail" style="color:red; font-size:12px">Please enter a valid email address.</span>
          </div>
          <div class="form-group">
            <input
              class="form-control"
              placeholder="ID Card/ Passport No."
              v-model="formData.idCardCode"
              pattern="[a-zA-Z0-9]+"
              required
            />
          </div>
          <div class="form-group">
            <input
              class="form-control"
              placeholder="First Name"
              v-model="formData.firstName"
              required
            />
          </div>
          <div class="form-group">
            <input
              class="form-control"
              placeholder="Last Name"
              v-model="formData.lastname"
              required
            />
          </div>
          <div class="form-group">
            <input
              class="form-control"
              placeholder="Company"
              v-model="formData.company"
              required
            />
          </div>
          <div class="form-group">
            <input
              class="form-control"
              placeholder="Phone Number"
              @keypress="checkDigit"
              pattern="[0]{1}[0-9]{9}"
              v-model="formData.phoneNumber"
              required
            />
            <span style="font-size:12px; color:red">Ex. 0999999999</span>
          </div>
          <div class="operation-modal">
            <button
              class="btn btn-login"
              style="width:150px; margin-right: 10px; margin-bottom: 10px;"
              type="submit"
            >
              <i class="bi bi-box-arrow-in-left" style="margin-right:10px"></i
              >LOGIN
            </button>
            <button
              class="btn btn-danger "
              style="width:150px  margin-right: 10px; margin-bottom: 10px;"
              type="button"
              @click="closeModal"
            >
              <i class="bi bi-x" style="margin-right:10px"></i>CANCEL
            </button>
          </div>
        </form>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import { login } from "@/js/login.js";
import { defineAsyncComponent } from "vue";
const Loading = defineAsyncComponent(() =>
  import("@/components/loading-overlay")
);
export default {
  props: {
    showModal: {
      type: Boolean,
    },
  },
  components: {
    Loading,
  },
  computed: {
    fncShowModal() {
      return this.showModal;
    },
  },
  data() {
    return {
      isLoading: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      formData: {
        email: "",
        firstName: "",
        lastname: "",
        idCardCode: "",
        company: '',
        phoneNumber: '',
      },
      errorEmail: false
    };
  },
  methods: {
    checkDigit (e) {
      if (this.formData.phoneNumber.length >= 10)  {
        e.preventDefault()
      }
    },
    closeModal() {
      this.$emit("closeModal", false);
    },
    checkFormatEmail () {
      if (!this.formData.email) return true
      else return this.reg.test(this.formData.email)
    },
    async submitLogin(e) {
      e.preventDefault()
      const checkEmail = this.checkFormatEmail()
      if (checkEmail) {
        this.errorEmail = false
        this.isLoading = true;
        try {
          const data = {
            ...this.formData,
            isAdUser: false,
          };
          await login(data);
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
        }
      } else {
        this.errorEmail = true
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-login-vendor {
  .form-group {
    margin-bottom: 2rem;
  }
  .btn-login {
    background-color: #083c5d;
    color: white;
  }
  .title-modal {
    text-align: center;
    padding-bottom: 20px;
  }
  .operation-modal {
    padding-top: 10px;
    text-align: center;
  }
  ::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep(.modal-content) {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    max-width: 400px !important;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
  }
  .modal__title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .dark-mode div ::v-deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
  }
}
</style>
